<template>
    <div class=container>

<center>
<table class=table>
<tbody><tr><td>  <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample01.zip"> <h6> 1-Felt <br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut01.gif">  </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample02.zip"> <h6> 2-Polyester <br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut02.gif">   </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample03.zip"> <h6> 3-Terrycloth <br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut03.gif">  </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample04.zip"> <h6> 4-Rough Plastic <br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut04.gif">   </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample05.zip"> <h6> 5-Leather <br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut05.gif">  </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample06.zip"> <h6> 6-Sandpaper <br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut06.gif">  </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample07.zip"> <h6> 7-Velvet <br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut07.gif">  </h6> </a>
</td></tr><tr>
<td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample08.zip"> <h6> 8-Pebbles<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut08.gif"/></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample09.zip"> <h6> 9-Frosted Glass<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut09.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample10.zip"> <h6> 10-Plaster_a<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut10.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample11.zip"> <h6> 11-Plaster_b<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut11.gif"> </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample12.zip"> <h6> 12-Rough Paper<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut12.gif"> </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample13.zip"> <h6> 13-Artificial Grass<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut13.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample14.zip"> <h6> 14-Roof Shingle<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut14.gif"></h6> </a>
</td></tr><tr>
<td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample15.zip"> <h6> 15-Aluminum Foil<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut15.gif"> </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample16.zip"> <h6> 16-Cork <br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut16.gif"> </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample17.zip"> <h6> 17-Rough Tile<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut17.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample18.zip"> <h6> 18-Rug_a<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut18.gif"> </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample19.zip"> <h6> 19-Rug_b<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut19.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample20.zip"> <h6>  20-Styrofoam<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut20.gif"> </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample21.zip"> <h6>  21-Sponge<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut21.gif"> </h6> </a>
</td></tr><tr>
<td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample22.zip"> <h6>  22-Lambswool<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut22.gif"> </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample23.zip"> <h6> 23-Lettuce Leaf<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut23.gif"> </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample24.zip"> <h6>  24-Rabbit Fur <br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut24.gif"> </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample25.zip"> <h6> 25-Quarry Tile<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut25.gif"> </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample26.zip"> <h6> 26-Loofa<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut26.gif">  </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample27.zip"> <h6>  27-Insulation <br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut27.gif"> </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample28.zip"> <h6> 28-Crumpled Paper <br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut28.gif"></h6> </a>
</td></tr><tr>
<td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample29.zip"> <h6> 29-(2 zoomed)<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut29.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample30.zip"> <h6>30-(11 zoomed) <br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut30.gif"> </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample31.zip"> <h6>31-(12 zoomed) <br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut31.gif"> </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample32.zip"> <h6> 32-(14 zoomed) <br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut32.gif"> </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample33.zip"> <h6>33-Slate_a <br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut33.gif">  </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample34.zip"> <h6> 34-Slate_b <br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut34.gif">  </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample35.zip"> <h6> 35-Painted Spheres <br><img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut35.gif"></h6> </a>
</td></tr><tr>
<td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample36.zip"> <h6>36-Limestone <br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut36.gif"> </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample37.zip"> <h6> 37-Brick_a<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut37.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample38.zip"> <h6> 38-Ribbed Paper<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut38.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample39.zip"> <h6> 39-Human Skin<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut39.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample40.zip"> <h6> 40-Straw<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut40.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample41.zip"> <h6> 41-Brick_b<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut41.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample42.zip"> <h6> 42-Corduroy<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut42.gif"></h6> </a>
</td></tr><tr>
<td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample43.zip"> <h6> 43-Salt Crystals<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut43.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample44.zip"> <h6> 44-Linen<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut44.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample45.zip"> <h6> 45-Concrete_a<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut45.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample46.zip"> <h6> 46-Cotton<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut46.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample47.zip"> <h6> 47-Stones<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut47.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample48.zip"> <h6> 48-Brown Bread<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut48.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample49.zip"> <h6> 49-Concrete_b<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut49.gif"></h6> </a>
</td></tr><tr>
<td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample50.zip"> <h6> 50-Concrete_c<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut50.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample51.zip"> <h6> 51-Corn Husk<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut51.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample52.zip"> <h6> 52-White Bread<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut52.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample53.zip"> <h6> 53-Soleirolia Plant<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut53.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample54.zip"> <h6> 54-Wood_a<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut54.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample55.zip"> <h6> 55-Orange Peel<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut55.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample56.zip"> <h6> 56-Wood_b<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut56.gif"></h6> </a>
</td></tr><tr>
<td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample57.zip"> <h6> 57-Peacock Feather<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut57.gif"> </h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample58.zip"> <h6> 58-Tree Bark<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut58.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample59.zip"> <h6> 59-Cracker_a<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut59.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample60.zip"> <h6> 60-Cracker_b<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut60.gif"></h6> </a>
</td><td> <a href="https://cave.cs.columbia.edu/old/exclude/curet/data/zips/sample61.zip"> <h6> 61-Moss<br> <img src="https://cave.cs.columbia.edu/old/software/curet/gif/cut61.gif"></h6> </a> 
</td></tr><tr>
</tr></tbody></table>
</center>

<hr>
<router-link to="/repository/CURET"> [Return to Database Home Page] </router-link>

    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>